import React from 'react';

import blogStyles from './Blog.module.scss';

const Blog = () => {
  // const data = {
  // }

  return (
    <div className={blogStyles.blog}>
    </div>
  );
}

export default Blog;
